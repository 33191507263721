import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import ProtectedRoute from 'components/ProtectedRoute/ProtectedRoute';
import PageNotFound from 'views/Common/PageNotFound';
import { Permission } from 'api/user';

const NotificationsView = React.lazy(() => import('views/Notifications/NotificationsView'));
const ScheduledMessagesView = React.lazy(
  () => import('views/ScheduledMessages/ScheduledMessagesView'),
);
const HomeView = React.lazy(() => import('views/Home/HomeView'));
const CbtBuilderRoutes = React.lazy(() => import('views/CbtBuilder/CbtBuilderRoutes'));
const ChatView = React.lazy(() => import('views/Chat/ChatView'));
const NewsView = React.lazy(() => import('views/News/NewsView'));
const UsersRoutes = React.lazy(() => import('views/UserSearch/UsersRoutes'));
const ActionItemsView = React.lazy(() => import('views/ActionItems/ActionItemsView'));
const RolesView = React.lazy(() => import('views/Roles/RolesView'));
const ActionItemTriggersView = React.lazy(
  () => import('views/ActionItemTriggers/ActionItemTriggersView'),
);
const UserSettingsView = React.lazy(() => import('views/Settings/UserSettingsView'));
const TherapyCoursesView = React.lazy(() => import('views/TherapyCourses/TherapyCoursesView'));
const TherapySessionsView = React.lazy(() => import('views/TherapySessions/TherapySessionsView'));
const TherapyUsersView = React.lazy(() => import('views/TherapyUsers/TherapyUsersView'));
const SystemPage = React.lazy(() => import('views/System/SystemPage'));
const DeadLettersPage = React.lazy(() => import('views/System/DeadLettersPage'));
const EmailBuilderPage = React.lazy(() => import('views/System/EmailBuilderPage'));

const AuthenticatedRoutes = () => {
  return (
    <Routes>
      <Route index element={<ProtectedRoute component={HomeView} />} />
      <Route
        path="chat"
        element={
          <ProtectedRoute
            component={ChatView}
            permission={[
              Permission.COACHING_READ,
              Permission.COACHING_WRITE,
              Permission.THERAPY_ALL,
            ]}
          />
        }
      />

      <Route path="users/*" element={<ProtectedRoute component={UsersRoutes} />} />
      <Route
        // @deprecated - in favor of /users/clients
        path="coach/:cid/*"
        element={<Navigate to="/users/clients" replace />}
      />
      <Route
        path="coach/action_items/*"
        element={
          <ProtectedRoute
            component={ActionItemsView}
            permission={[Permission.COACHING_READ, Permission.COACHING_WRITE]}
          />
        }
      />
      <Route
        path="coach/scheduled_messages/*"
        element={
          <ProtectedRoute
            component={ScheduledMessagesView}
            permission={Permission.COACHING_WRITE}
          />
        }
      />
      <Route
        path="action_item_triggers/*"
        element={
          <ProtectedRoute
            component={ActionItemTriggersView}
            permission={Permission.MODERATION_WRITE}
          />
        }
      />
      <Route
        path="cbt/*"
        element={
          <ProtectedRoute
            component={CbtBuilderRoutes}
            permission={[Permission.CONTENT_READ, Permission.CONTENT_WRITE]}
          />
        }
      />
      <Route
        path="notifications/*"
        element={
          <ProtectedRoute
            component={NotificationsView}
            permission={[Permission.CONTENT_READ, Permission.CONTENT_WRITE]}
          />
        }
      />
      <Route
        path="news/*"
        element={
          <ProtectedRoute
            component={NewsView}
            permission={[Permission.CONTENT_READ, Permission.CONTENT_WRITE]}
          />
        }
      />
      <Route
        path="roles/*"
        element={
          <ProtectedRoute component={RolesView} permission={Permission.ADMINISTRATION_ALL} />
        }
      />
      <Route
        path="therapy/users/:userId"
        element={<ProtectedRoute component={TherapyUsersView} />}
      />
      <Route
        path="therapy/*"
        element={
          <ProtectedRoute
            component={TherapyCoursesView}
            permission={[Permission.CONTENT_READ, Permission.CONTENT_WRITE]}
          />
        }
      />
      <Route
        path="therapy-sessions/*"
        element={
          <ProtectedRoute
            component={TherapySessionsView}
            permission={[
              Permission.MODERATION_READ,
              Permission.MODERATION_WRITE,
              Permission.THERAPY_ALL,
            ]}
          />
        }
      />
      <Route path="settings/user/*" element={<ProtectedRoute component={UserSettingsView} />} />
      <Route
        path="system/pipelines"
        element={<ProtectedRoute component={SystemPage} permission={Permission.DEV_ALL} />}
      />
      <Route
        path="system/dead-letters"
        element={<ProtectedRoute component={DeadLettersPage} permission={Permission.DEV_ALL} />}
      />
      <Route
        path="system/email-builder"
        element={<ProtectedRoute component={EmailBuilderPage} permission={Permission.DEV_ALL} />}
      />
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
};

export default AuthenticatedRoutes;
