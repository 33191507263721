import { Avatar, Group, SelectProps, Text, Skeleton } from '@mantine/core';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { SearchUsersRequest, getUser, searchUsers } from 'api/users';
import {
  InfiniteList,
  InfiniteListItem,
  InfiniteListItemProps,
} from 'components/InfiniteList/InfiniteList';
import React from 'react';
import c from 'classnames';
import { InfiniteDropdownProps, InfiniteSelect } from 'components/InfiniteSelect/InfiniteSelect';
import { mutationErrorHandler } from 'helpers/queryUtils';
import { useTranslation } from 'react-i18next';
import SubscriptionBadge from 'views/Users/components/SubscriptionBadge';
import { getUserDisplayName, hasAccessTimeLeft } from 'views/Users/helpers/UsersViewUtils';

import useStyles from './UserSelect.styles';
import UserRoleBadge from 'views/Users/components/UserRoleBadge';
import { UserView } from 'api/user';

const ITEMS_PER_PAGE = 30;
const ITEM_HEIGHT = 40;

const itemToLabel = (item: UserView) => getUserDisplayName(item) ?? '';
const itemToValue = (item: UserView) => item?.userId;

export interface UserSelectProps
  extends Omit<SelectProps, 'data' | 'onChange' | 'value' | 'defaultValue'> {
  value?: InfiniteListItem<UserView> | string | null;
  defaultValue?: InfiniteListItem<UserView> | null;
  filters?: Omit<SearchUsersRequest, 'sortBy' | 'order' | 'page' | 'limit'>;
  onChange?: (value: InfiniteListItem<UserView> | null) => void;
  showSubscriptionStatus?: boolean;
  showRole?: boolean;
}

const UserSelectContext = React.createContext<UserSelectProps | null>(null);

const Dropdown = React.forwardRef<HTMLDivElement, InfiniteDropdownProps>(
  ({ onItemSelect, itemComponent: Item, query, nothingFound }: InfiniteDropdownProps, ref) => {
    const { classes } = useStyles();
    const { filters, value, showSubscriptionStatus, showRole } =
      React.useContext(UserSelectContext) ?? {};
    const queryClient = useQueryClient();

    const getItemsQuery = React.useCallback(
      (start_index: number, _end_index: number, filterStr?: string) => {
        const queryParams = {
          ...filters,
          page: Math.floor(start_index / ITEMS_PER_PAGE) + 1,
          limit: ITEMS_PER_PAGE,
        };
        if (filterStr) {
          queryParams.textSearch = filterStr;
        }
        return queryClient.fetchQuery(['userViews', queryParams], () => searchUsers(queryParams));
      },
      [filters, queryClient],
    );

    const rowRenderer = ({ index, key, style, item }: InfiniteListItemProps<UserView>) => {
      const isLoading = !item;
      const isSelected =
        item?.userId && itemToValue((value as InfiniteListItem<UserView>)?.value) === item?.userId;

      return (
        <div
          key={key}
          style={style}
          onClick={
            isLoading
              ? undefined
              : () => onItemSelect?.({ label: itemToLabel(item), value: item, idx: index })
          }>
          <Group
            spacing="sm"
            noWrap
            className={c(classes.item, isSelected && classes.isItemSelected)}>
            {isLoading ? (
              <>
                <Skeleton style={{ minWidth: '26px' }} width={26} height={26} radius={'xl'} />
                <Skeleton height={14} />
              </>
            ) : (
              <>
                <Avatar size={'sm'} src={item.avatarUrl} radius={'xl'} />
                <Text size="sm" weight={500} truncate>
                  {getUserDisplayName(item)}
                </Text>

                {showSubscriptionStatus && (
                  <SubscriptionBadge
                    status={item.subscriptionStatus}
                    size="xs"
                    color={!hasAccessTimeLeft(item) ? 'gray' : undefined}
                  />
                )}

                {showRole && (
                  <UserRoleBadge
                    role={item.role ?? item.userRole?.role}
                    size="xs"
                    sx={{ overflow: 'visible' }}
                  />
                )}
              </>
            )}
          </Group>
        </div>
      );
    };

    return (
      <div className={classes.itemsWrapper} ref={ref}>
        <InfiniteList<UserView>
          itemToLabel={itemToLabel}
          itemToValue={itemToValue}
          getItemsQuery={getItemsQuery}
          minimumBatchSize={ITEMS_PER_PAGE}
          rowRenderer={rowRenderer}
          rowHeight={ITEM_HEIGHT}
          filterStr={query}
          nothingFound={nothingFound}
        />
      </div>
    );
  },
);

export const UserSelect = ({ value, onChange, filters, ...props }: UserSelectProps) => {
  const { t } = useTranslation('core');
  const isQueryEnabled = !!(value && typeof value === 'string');

  const { isLoading, data } = useQuery(['user', value], () => getUser(value as string), {
    enabled: isQueryEnabled,
    onError: mutationErrorHandler,
  });

  const selectedValue = React.useMemo(() => {
    if (!value) {
      return null;
    }

    if (typeof value !== 'string') {
      return value;
    }

    return data ? { label: itemToLabel(data), value: data } : null;
  }, [data, value]);

  const handleChange = React.useCallback(
    (option: InfiniteListItem<UserView> | null) => {
      onChange?.(option);
    },
    [onChange],
  );

  return (
    // NOTE: there's no other way to pass props to custom components, so we use React context
    <UserSelectContext.Provider
      value={{ ...props, onChange: handleChange, filters, value: selectedValue }}>
      <InfiniteSelect
        disabled={isQueryEnabled && isLoading}
        value={isQueryEnabled && isLoading ? null : selectedValue ?? null}
        onChange={handleChange}
        icon={<Avatar size={'sm'} src={selectedValue?.value?.avatarUrl} radius={'xl'} />}
        dropdownComponent={Dropdown}
        searchable
        maxDropdownHeight={400}
        itemToLabel={itemToLabel}
        itemToValue={itemToValue}
        nothingFound={t('core:nothing-found')}
        clearable
        {...props}
      />
    </UserSelectContext.Provider>
  );
};
