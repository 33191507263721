import React from 'react';
import { Badge, MantineColor, BadgeProps } from '@mantine/core';
import { Role } from 'api/user';

const roleColors: Record<Role, MantineColor> = {
  [Role.ADMIN]: 'red',
  [Role.DEV]: 'indigo',
  [Role.COACH]: 'grape',
  [Role.COACH_MANAGER]: 'violet',
  [Role.PRODUCT]: 'blue',
  [Role.THERAPIST]: 'lime',
  [Role.USER]: 'yellow',
};

interface UserRoleBadgeProps extends BadgeProps {
  role?: Role;
}

const UserRoleBadge = ({ role = Role.USER, ...props }: UserRoleBadgeProps) => {
  return (
    <Badge color={roleColors[role]} {...props}>
      {role.toUpperCase()}
    </Badge>
  );
};

export default UserRoleBadge;
