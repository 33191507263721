import { LoadingOverlay } from '@mantine/core';
import { Permission } from 'api/user';
import { can } from 'helpers/userUtils';
import React from 'react';
import { useMe } from 'store/userStore';
import PageForbidden from 'views/Common/PageForbidden';

export type WithPermissions = {
  permission?: Permission | Permission[];
};

function withPermissions<P extends JSX.IntrinsicAttributes>(
  WrappedComponent: React.ComponentType<P & WithPermissions>,
) {
  const displayName = WrappedComponent.displayName || WrappedComponent.name || 'Component';

  const ComponentWithPermissionsProps: React.FC<P & WithPermissions> = (props) => {
    const me = useMe();
    const permissions = (
      Array.isArray(props.permission)
        ? props.permission
        : props.permission
          ? [props.permission]
          : []
    ) as Permission[];

    if (!me) return <LoadingOverlay visible />;

    // No special permission required to access the route
    if (!permissions.length) return <WrappedComponent {...props} />;

    // Permission check
    const hasPermission = can(me, permissions[0], ...permissions.slice(1));
    return hasPermission ? <WrappedComponent {...props} /> : <PageForbidden />;
  };

  ComponentWithPermissionsProps.displayName = `withPermissions(${displayName})`;

  return ComponentWithPermissionsProps;
}

export default withPermissions;
