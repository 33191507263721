import api from 'api/client';
import { queryResponseValidator } from 'helpers/queryUtils';
import { SortOrder, TrialPeriodParams } from 'types';

import { STATUS } from './types';
import { z } from 'zod';
import { booleanSchema, intSchema } from './shared/common.schema';
import {
  followupBucketSchema,
  followupTypeSchema,
  paymentMethodSchema,
  SubscriptionStatus,
  subscriptionStatusSchema,
  therapyClientSchema,
  User,
  userRoleSchema,
  UserView,
  userViewSchema,
} from './user';

const paymentDetailsSchema = z.object({
  id: z.string(),
  brand: z.string(),
  last4: z.string(),
  expMonth: z.number(),
  expYear: z.number(),
  isDefault: z.boolean(),
});

export type PaymentDetails = z.infer<typeof paymentDetailsSchema>;

const userAddressSchema = z.object({
  zipCode: z.string(),
  street: z.string(),
  city: z.string(),
  state: z.string(),
});

export type UserAddress = z.infer<typeof userAddressSchema>;

export const userInsuranceSchema = z.object({
  memberId: z.string(),
  payerId: z.string(),
  isValid: z.boolean(),
  validationError: z.array(z.object({ error_mesg: z.string() })),
});

export type UserInsurance = z.infer<typeof userInsuranceSchema>;

const prismaQuerySchema = z.object({
  OR: z.any().optional(),
  AND: z.any().optional(),
  isNot: z.any().optional(),
  is: z.any().optional(),
  in: z.array(z.any()).optional(),
  gte: z.any().or(z.null()).optional(),
  lte: z.any().or(z.null()).optional(),
  some: z.any().or(z.null()).optional(),
});

const querySchema = z
  .object({
    subscriptionStatus: subscriptionStatusSchema.optional(),
    coachId: z.string().optional(),
    isCoach: booleanSchema,
    platform: z.string().optional(),
    paymentMethod: paymentMethodSchema.optional(),
    lastSeenDate: prismaQuerySchema.optional(),
    isNotificationsAllowed: booleanSchema.optional(),
    clientCompany: z.string().optional(),
    followupBucket: followupBucketSchema.merge(prismaQuerySchema).or(z.null()).optional(),
    userRole: userRoleSchema.merge(prismaQuerySchema).or(z.null()).optional(),
    therapies: prismaQuerySchema.optional(),
  })
  .merge(prismaQuerySchema);

export const userSearchRequestSchema = z.object({
  query: querySchema.optional(),
  limit: intSchema.optional(),
  page: intSchema.optional(),
  format: z.enum(['json', 'csv']).optional(),
  sortBy: z.string().optional(),
  order: z.enum(['asc', 'desc']).optional(),
  include: z.array(z.string()).optional(),
  textSearch: z.string().optional(),
  displayDeleted: booleanSchema,
  displayActive: booleanSchema,
  followupType: followupTypeSchema.optional(),
});

export const therapyClientQuerySchema = z
  .object({
    therapistId: z.string(),
  })
  .merge(prismaQuerySchema);

export const searchTherapyClientRequestSchema = z.object({
  query: therapyClientQuerySchema.optional(),
  limit: intSchema.optional(),
  page: intSchema.optional(),
  format: z.enum(['json', 'csv']).optional(),
  sortBy: z.string().optional(),
  order: z.enum(['asc', 'desc']).optional(),
  textSearch: z.string().optional(),
});

export const searchUsersResponseSchema = z.object({
  status: z.enum([STATUS.OK, STATUS.ERROR]),
  total: z.number(),
  items: z.array(userViewSchema),
});

export const searchTherapyClientResponseSchema = z.object({
  status: z.enum([STATUS.OK, STATUS.ERROR]),
  total: z.number(),
  page: z.number(),
  items: z.array(therapyClientSchema),
});

export type SearchTherapyClientResponse = z.infer<typeof searchTherapyClientResponseSchema>;

export type SearchTherapyClientRequest = z.infer<typeof searchTherapyClientRequestSchema>;
export type SearchUsersRequest = z.infer<typeof userSearchRequestSchema>;
export type SearchUsersResponse = z.infer<typeof searchUsersResponseSchema>;
export type TherapyClientCardItem = z.infer<typeof therapyClientSchema>;

export type UsersFilter = {
  isCoach?: boolean;
  textSearch?: string;
  subscriptionStatus?: SubscriptionStatus;
  coachId?: string | null;
  sortBy?: keyof User;
  order?: SortOrder;
  displayDeleted?: boolean;
  isFollowupRequired?: boolean;
};

export type SearchUsersParams = {
  query?: UsersFilter;
  limit?: number;
  page?: number;
};

const usersEndpoint = `user/search`;

export interface GetUsersResponse {
  items: User[];
  total: number;
}

export interface GetUserResponse {
  user: UserView;
  onboarding: object;
}

export const getUsers = async (body?: SearchUsersParams) => {
  const json = body ?? {};
  const { items, total } = await api.post(usersEndpoint, { json }).json<GetUsersResponse>();
  return { items, total };
};

export const chatUsers = async (body?: SearchUsersRequest) => {
  return api
    .post(`chat/users`, { json: queryResponseValidator(userSearchRequestSchema)(body) ?? {} })
    .json()
    .then(queryResponseValidator<SearchUsersResponse>(searchUsersResponseSchema));
};

export const searchUsers = async (body?: SearchUsersRequest) => {
  return api
    .post(`user/v2/search`, { json: queryResponseValidator(userSearchRequestSchema)(body) ?? {} })
    .json()
    .then(queryResponseValidator<SearchUsersResponse>(searchUsersResponseSchema));
};

export const getUsersAsCsv = async (body?: SearchUsersParams) => {
  const json = body ?? {};
  const csv = await api.post(`user/v2/search?format=csv`, { json }).text();
  return csv;
};

export const getUser = async (userId: string) => {
  const response = await api.get(`user/v2/${userId}`).json<GetUserResponse>();
  return response.user;
};

export const deleteUser = async (userId: string) => {
  const response = await api.delete(`user/${userId}`).json<GetUserResponse>();
  return response.user;
};

export const setTrialPeriod = async (body?: TrialPeriodParams) => {
  const json = body ?? {};
  const response = await api.post('user/trial', { json }).json();
  return response;
};

export const suspendAccess = async (userId: string) => {
  const response = await api.put(`user/suspend-access/${userId}`).json();
  return response;
};

export const fetchUserAddress = async (userId: string) => {
  const { userAddress } = await api
    .get(`user/${userId}/address`)
    .json<{ userAddress: UserAddress }>();
  return userAddress;
};

export const fetchUserPaymentMethod = async (userId: string) => {
  const { data } = await api
    .get(`finance/payment-method/${userId}`)
    .json<{ data: PaymentDetails }>();
  return data;
};

export const fetchUserInsurance = async (userId: string) => {
  const { data } = await api.get(`finance/insurance/${userId}`).json<{ data: UserInsurance }>();
  return data;
};

export const updateUserInsurance = async (userId: string, data: UserInsurance) => {
  await api.post(`finance/insurance/${userId}`, { json: data }).json();
};

export const updateUserPhone = async (userId: string, phone: string) => {
  await api.put(`user/${userId}/phone`, { json: { phone } }).json();
};

export const searchTherapyClientByTherapist = async (body?: SearchTherapyClientRequest) => {
  return api
    .post(`therapy/search/clients`, {
      json: body,
    })
    .json()
    .then((res) => res as SearchTherapyClientResponse);
};
