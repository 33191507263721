import { Permission, Role, UserView } from 'api/user';
import { InfiniteListItem } from 'components/InfiniteList/InfiniteList';
import { can } from 'helpers/userUtils';
import React from 'react';
import { useMe } from 'store/userStore';
import { UserSelect } from 'views/UserSearch/components/UserSelect';

export const Impersonator = () => {
  const me = useMe();

  const asUserId = React.useMemo(() => localStorage.getItem('x-impersonate'), []);

  const filters = React.useMemo(
    () => ({
      query: { userRole: { role: { in: [Role.THERAPIST, Role.COACH] } } },
      include: ['userRole'],
    }),
    [],
  );

  const handleSwitchUser = React.useCallback(async (option: InfiniteListItem<UserView> | null) => {
    const newAsUserId = option?.value.userId ?? null;
    if (newAsUserId) {
      localStorage.setItem('x-impersonate', newAsUserId);
    } else {
      localStorage.removeItem('x-impersonate');
    }
    // redirect to home page
    window.location.replace('/');
  }, []);

  return can(me, Permission.ADMINISTRATION_IMPERSONATION) || !!asUserId ? (
    <UserSelect
      sx={{ width: '250px' }}
      placeholder="Pick a coach..."
      filters={filters}
      value={asUserId}
      onChange={handleSwitchUser}
      clearable
      showRole
    />
  ) : null;
};
