import ky from 'ky';
import { useUserStore } from 'store/userStore';
import { API_URL } from 'config';

const api = ky.extend({
  prefixUrl: API_URL,
  timeout: 60 * 1000,
  hooks: {
    beforeRequest: [
      (request) => {
        const accessToken = useUserStore.getState().accessToken;
        request.headers.set('Authorization', `Bearer ${accessToken}`);

        if (localStorage.getItem('x-impersonate')) {
          request.headers.set('Content-Language', localStorage.getItem('x-impersonate') || '');
        }
      },
    ],
  },
});

export default api;
